.switch {
	position: relative;
	background-color: #333;
	display: flex;
	padding: 5px;
	border-radius: 10px;
	flex-direction: row;
	justify-content: space-around;
	margin-bottom: 1.5vh;
	width: 100%;
}
.switch-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.switch-text {
	color: #fff;
	font-weight: 400;
	font-size: 0.6em;
	text-align: center;
}

.checkbox-wrapper-2 .ikxBAC {
	appearance: none;
	background-color: #666;
	border-radius: 72px;
	border-style: none;
	flex-shrink: 0;
	height: 35px;
	margin: 0 0 -5px 0;
	position: relative;
	width: 18px;
	padding: 0;
}

.checkbox-wrapper-2 .ikxBAC::before {
	bottom: 0px;
	content: '';
	left: 0px;
	position: absolute;
	right: 0px;
	top: 0px;
}

.checkbox-wrapper-2 .ikxBAC,
.checkbox-wrapper-2 .ikxBAC::after {
	transition: all 200ms ease-out;
}

.checkbox-wrapper-2 .ikxBAC::after {
	background-color: #fff;
	border-radius: 50%;
	content: '';
	height: 16px;
	left: 1px;
	position: absolute;
	top: 17px;
	width: 16px;
}

.checkbox-wrapper-2 input[type='checkbox'] {
	cursor: default;
}

.checkbox-wrapper-2 .ikxBAC:checked {
	background-color: #d6d6d6;
}

.checkbox-wrapper-2 .ikxBAC:checked::after {
	background-color: #ffffff;
	top: 1px;
}

.checkbox-wrapper-2 :focus:not(.focus-visible) {
	outline: 0;
}
.checkbox-wrapper-2 label {
	display: none;
}
