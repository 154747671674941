.input {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: space-between;
}
input,
button {
	width: auto;
	font-family: 'Rubik', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 1em;
	font-weight: 400;
	padding: 0.5em 0;
	border: 1px solid #ccc;
	border-radius: 0.5em;
	margin-bottom: 1vh;
	text-align: center;
}
button {
	font-weight: 500;
	cursor: pointer;
}
button:hover {
	background-color: #ff79e9;
	color: #fff;
	transition: background-color 0.5s, color 0.5s;
}
.center {
	text-align: center !important;
}
.resultat {
	font-size: 2em;
}
.modeSelect {
	cursor: pointer;
}

.active {
	color: #333;
	font-weight: 500;
}
.setMode {
	margin-bottom: 3px;
	width: 100%;
	display: flex;
	align-items: space-between;
}
.setMode div:nth-child(1) {
	width: 50%;
	text-align: left;
	font-weight: 500;
}
.setMode div:nth-child(2) {
	width: 50%;
	text-align: right;
}

.observera {
	line-height: 1em;
	padding: 0 0 1.2em;
	text-align: justify;
	font-size: 0.85rem;
}
.observera .bold {
	font-weight: 500;
	color: brown;
}
